import React from "react"
import { Helmet } from "react-helmet"
import "react-responsive-modal/styles.css"
import Layout from "../../components/layout"
import Shapefour from "../../images/bannerElement/white-design-element-career.svg"
import Shapeone from "../../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../../images/bannerElement/white-design-element-two.svg"
import "../../styles/careers.css"

export default function MarketingManager() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Account Manager Performance Marketing | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="careerstyle marketing-manager">
          <section id="Banner">
            <div className="container">
              <div className="banner-holder">
                <div className="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div className="title">
                  <div className="banner-title">
                    <h1>Careers</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2 className="career-heading">Performance Marketing Manager</h2>
              <br />
              <h4>Location</h4>
              <p className="career-para">Bangalore, India</p>
              <br />
              <h4>Job Summary</h4>
              <p className="career-para">
                The Performance Marketing Manager will be responsible for
                leading our Client’s Performance Marketing campaigns across
                platforms such as Google Ads, Facebook Ads, Linkedin Ads,
                overseeing a monthly budget of $1M+.This includes developing and
                implementing campaigns, optimizing current campaigns, and
                leading and developing a team of analysts with the intent of
                building a funnel of demand. This position must be able to take
                a high-functioning channel to the next level and will report to
                the Director – Paid Acquisition.
              </p>
              <h4>Job Responsibilities</h4>
              <ul className="bullet-point">
                <li>
                  Manage PPC efforts on Google Ads, Facebook Ads, Linekdin Ads
                  and ABM platforms
                </li>
                <li>
                  Expand advertising campaigns to drive continued growth in a
                  high-performing marketing channel.
                </li>
                <li>Ensure accurate conversion tracking and attribution.</li>
                <li>
                  Work closely with creative and content teams to seamlessly
                  execute Paid Search campaigns.
                </li>
                <li>
                  Measure and improve key performance indicators such as
                  Impressions, CTR, CPC, Conversion, Lead Quality, Spend, etc.
                </li>
                <li>
                  Learn the business. Understand services to build effective
                  keyword/campaign strategies.
                </li>
                <li>
                  Lead and mentor a team to develop their individual growth and
                  success.
                </li>
                <li>
                  Responsible for crafting and implementing PPC strategy for
                  clients.
                </li>
                <li>
                  Optimize campaigns using Web Analytics tools and provide
                  insights to clients.
                </li>
                <li>
                  Communicate any roadblocks in the performance of campaigns.
                </li>
                <li>
                  To lead client calls and present reports on a weekly basis.
                </li>
                <li>Support pitches and any productization initiatives.</li>
              </ul>
              <h4>Requirements</h4>
              <ul className="bullet-point">
                <li>
                  Proficiency with ad platforms such as Google, Facebook,
                  LinkedIn
                </li>
                <li>
                  Outstanding ability to think creatively and identify and
                  resolve problems.
                </li>
                <li>
                  Ability to clearly and effectively articulate thoughts and
                  points.
                </li>
                <li>
                  Excellent analytical, organizational, project management and
                  time management skills.
                </li>
                <li>
                  Understanding of performance marketing best practices and
                  current trends.
                </li>
                <li>
                  General passion and excitement towards the Paid Search
                  channel.
                </li>
                <li>Flexible to work in US hours.</li>
                <li>Must have worked in a digital marketing agency.</li>
                <li>5+ years of Performance Marketing Experience</li>
                <li>3+ Years of Agency Experience</li>
                <li>Google Ads / Facebook Ads Certified</li>
              </ul>
              <div class="cta">
                <a href="mailto:careers@position2.com" class="apply-cta">
                  Apply
                </a>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
